// @flow

import React from 'react';

import type { PageProps } from '../types';

import Layout from '../components/layout';
import { Link, graphql } from 'gatsby';

const Tags = ({ pageContext, data, location }: PageProps) => {
  const { tag } = pageContext;
  const { edges } = data.allMarkdownRemark;

  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      <h1># {tag}</h1>
      <Link to="/tags" rel="prev">
        ← All tags
      </Link>

      <div
        style={{
          marginTop: '28px',
          marginBottom: '28px',
        }}
      >
        {edges.map(({ node }) => {
          const { slug } = node.fields;
          const { title, date } = node.frontmatter;

          return (
            <div key={slug}>
              - <time dateTime={date}>{date}</time>:{' '}
              <Link to={slug}>{title}</Link>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
          }
        }
      }
    }
  }
`;
